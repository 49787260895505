import React, { Component } from 'react';
import UploadImageButton from '../components/UploadImageButton';
import { getPluralForm } from '../helpers/getPluralForm';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import { BottomOptions } from '../components/BottomOptions';
import { Modal, ModalBody } from 'reactstrap';
import ImageModal from '../components/ImageModal';
import Albums from '../components/Albums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import helpers from '../helpers';
import api from '../api';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer } from 'mobx-react';
import { Loader } from '../components/Loader';

class FavouritesPage extends Component {
    static displayName = FavouritesPage.name;
    inicialCount = 30;

    constructor(props) {
        super(props);

        this.galleryStore = new GalleryStore(null, false, true);

        this.state = {
            addModalShow: false,
            newAlbumName: "",
        };
        this.onPhotoSelect = this.onPhotoSelect.bind(this);
        this.onAltSelect = this.onAltSelect.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);
        this.onClearSelection = this.onClearSelection.bind(this);
        this.onDeleteSelected = this.onDeleteSelected.bind(this);
        this.onAddToAlbum = this.onAddToAlbum.bind(this);
        this.onPhotoDelete = this.onPhotoDelete.bind(this);
        this.onCreateAlbum = this.onCreateAlbum.bind(this);
        this.onAlbumClick = this.onAlbumClick.bind(this);
        this.onArchiveSelected = this.onArchiveSelected.bind(this);
        this.onRemoveFromFavourites = this.onRemoveFromFavourites.bind(this);
    }

    onPhotoSelect(selectedPhoto) {
        if (this.galleryStore.selectedPhotos.length > 0) {
            this.onAltSelect(selectedPhoto);
        } else {
            this.galleryStore.selectPhoto(selectedPhoto);
            this.imageModalRef.show();
        }
    }

    onAltSelect(selectedPhoto) {
        this.galleryStore.toggleMultiSelection(selectedPhoto.id);
    }

    onZoomClick(selectedPhoto) {
        this.galleryStore.selectPhoto(selectedPhoto);
        this.imageModalRef.show();
    }

    onClearSelection() {
        this.galleryStore.clearMultiSelection();
    }

    async onDeleteSelected() {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(
                await api.photos.deletePhotos(this.galleryStore.selectedPhotos),
                "Ошибка удаления фотографии");
            if (response.ok) {
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onAddToAlbum() {
        this.setState({ addModalShow: true });
    }

    async onCreateAlbum() {
        if (this.state.newAlbumName.length == 0) {
            window.alert("Введите название альбома");
            return;
        }
        let response = await helpers.alertError(await api.albums.createAlbum(this.state.newAlbumName, "", this.galleryStore.selectedPhotos, this.galleryStore.selectedPhotos[0]));
        if (response.ok) {
            let albumId = await response.text();
            this.props.history.push(`/album/${albumId}`);
        }
    }

    async onPhotoDelete(photo) {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(await api.photos.deletePhotos([photo?.id]), "Ошибка удаления фотографии");
            if (response.ok) {
                this.imageModalRef.hide();
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onAlbumClick(album) {
        let response = await helpers.alertError(await api.albums.addToAlbum(album.id, this.galleryStore.selectedPhotos));
        if (response.ok) {
            this.props.history.push(`/album/${album.id}`);
        }
    }

    async onRemoveFromFavourites() {
        if (await this.galleryStore.markSelectedPhotosFavourite(false)) {
            this.galleryStore.refresh();
            await this.galleryStore.loadPhotos(0, this.inicialCount);
        }
    }

    async onArchiveSelected() {
        let response = await helpers.alertError(await api.photos.archivePhotos(this.galleryStore.selectedPhotos, true));
        if (response.ok) {
            this.galleryStore.refresh();
            await this.galleryStore.loadPhotos(0, this.inicialCount);
        }
    }

    render() {
        const toggle = () => this.setState({ addModalShow: false });

        return (
            <Provider galleryStore={this.galleryStore} >
                <div className="pb-3">
                    <div className="sticky-header pb-3 pt-3 row">
                        <div className="col-xl-10 col-md-9 col-7">
                            <span className="h1 me-3">Избранное</span>
                            <span className="h5">
                                <Loader isLoading={this.galleryStore.loading && !this.galleryStore.totalCount}>
                                    {this.galleryStore.totalCount} {getPluralForm(this.galleryStore.totalCount, "объект", "объекта", "объектов")}
                                </Loader>
                            </span>
                        </div>
                        <div className="col-xl-2 col-md-3 col-5">
                            <UploadImageButton />
                        </div>
                    </div>
                    <ContainerSizeSensor>
                        <Gallery
                            ref={elem => this.galleryRef = elem}
                            onSelect={this.onPhotoSelect}
                            onTotalChanged={this.onTotalChanged}
                            onAltSelect={this.onAltSelect}
                            selectedPhotos={this.galleryStore.selectedPhotos}
                            onZoomClick={this.onZoomClick}
                            favourite={true}
                        />
                    </ContainerSizeSensor>
                    <ImageModal
                        ref={(elem) => { this.imageModalRef = elem }}
                        photo={this.galleryStore.selectedPhoto}
                        canDownload={true}
                        onDelete={this.onPhotoDelete}
                        onMarkFavourite={async () => { await this.galleryStore.markSelectedPhotoFavourite() }} />
                    <BottomOptions
                        selectedItems={this.galleryStore.selectedPhotos}
                        onRemoveFromFavourites={this.onRemoveFromFavourites}
                        onArchive={this.onArchiveSelected}
                        onDelete={this.onDeleteSelected}
                        onAdd={this.onAddToAlbum}
                        onClear={this.onClearSelection} />
                    <Modal
                        isOpen={this.state.addModalShow}
                        toggle={toggle}
                        modalTransition={{ timeout: 200 }}
                        className="modal-xl modal-dialog-centered">
                        {/*<ModalHeader toggle={toggle}>Выберите существующий альбом или введите название нового альбома</ModalHeader>*/}
                        <ModalBody>
                            <p className="fs-5">Создайте новый альбом</p>
                            <div className="input-group mb-3">
                                <input onChange={(e) => { this.setState({ newAlbumName: e.target.value }); }} className="form-control form-control-lg" type="text" placeholder="Название новго альбома" />
                                <button onClick={this.onCreateAlbum} className="btn btn-primary btn-xl"><FontAwesomeIcon icon={faFloppyDisk} /> Создать альбом</button>
                            </div>
                            <p className="fs-5">Или выберите существующий</p>
                            <Albums
                                onAlbumClick={this.onAlbumClick}
                                showOptions={false}
                            />
                        </ModalBody>
                    </Modal>
                </div>
            </Provider>
        );
    }
}

export default observer(FavouritesPage)