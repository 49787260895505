import api from '../api';
import helpers from '../helpers';
import { action, makeObservable, observable, runInAction } from 'mobx';

export default class AlbumsStore {
    constructor() {
        makeObservable(this, {
            albums: observable,
            loading: observable,

            loadAlbums: action,
        });
    }

    albums = [];
    loading = true;

    loadAlbums = async () => {
        this.loading = true;
        let response = await helpers.alertError(await api.albums.getAlbums());
        if (response.ok) {
            const data = await response.json();
            runInAction(() => {
                this.albums = data;
                this.loading = false;
            });
        }
    }
}