import React, { Component } from 'react';
import { getPluralForm } from '../helpers/getPluralForm';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import { BottomOptions } from '../components/BottomOptions';
import ImageModal from '../components/ImageModal';
import helpers from '../helpers';
import api from '../api';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer } from 'mobx-react';
import { Loader } from '../components/Loader';

class ArchivePage extends Component {
    static displayName = ArchivePage.name;
    inicialCount = 30;

    constructor(props) {
        super(props);

        this.galleryStore = new GalleryStore(null, true, false);

        this.state = {
            totalCount: 0,
            selectedPhoto: null,
            albumName: "Галерея",
            multipleSelected: [],
        };
        this.onPhotoSelect = this.onPhotoSelect.bind(this);
        this.onAltSelect = this.onAltSelect.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);
        this.onClearSelection = this.onClearSelection.bind(this);
        this.onDeleteSelected = this.onDeleteSelected.bind(this);
        this.onPhotoDelete = this.onPhotoDelete.bind(this);
        this.onRestore = this.onRestore.bind(this);
    }

    onPhotoSelect(selectedPhoto) {
        if (this.galleryStore.selectedPhotos.length > 0) {
            this.onAltSelect(selectedPhoto);
        } else {
            this.galleryStore.selectPhoto(selectedPhoto);
            this.imageModalRef.show();
        }
    }

    onAltSelect(selectedPhoto) {
        this.galleryStore.toggleMultiSelection(selectedPhoto.id);
    }

    onZoomClick(selectedPhoto) {
        this.galleryStore.selectPhoto(selectedPhoto);
        this.imageModalRef.show();
    }

    onClearSelection() {
        this.galleryStore.clearMultiSelection();
    }

    async onDeleteSelected() {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(
                await api.photos.deletePhotos(this.galleryStore.selectedPhotos),
                "Ошибка удаления фотографии");
            if (response.ok) {
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onPhotoDelete(photo) {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(await api.photos.deletePhotos([photo?.id]), "Ошибка удаления фотографии");
            if (response.ok) {
                this.imageModalRef.hide();
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onRestore() {
        let response = await helpers.alertError(await api.photos.archivePhotos(this.galleryStore.selectedPhotos, false));
        if (response.ok) {
            this.galleryStore.refresh();
            await this.galleryStore.loadPhotos(0, this.inicialCount);
        }
    }

    render() {
        return (
            <Provider galleryStore={this.galleryStore} >
                <div className="pb-3">
                    <div className="sticky-header pb-3 pt-3 row">
                        <div className="col-xl-10 col-md-9 col-7">
                            <span className="h1 me-3">Архив</span>
                            <span className="h5">
                                <Loader isLoading={this.galleryStore.loading && !this.galleryStore.totalCount}>
                                    {this.galleryStore.totalCount} {getPluralForm(this.galleryStore.totalCount, "объект", "объекта", "объектов")}
                                </Loader>
                            </span>
                        </div>
                    </div>
                    <ContainerSizeSensor>
                        <Gallery
                            onSelect={this.onPhotoSelect}
                            onTotalChanged={this.onTotalChanged}
                            onAltSelect={this.onAltSelect}
                            selectedPhotos={this.galleryStore.selectedPhotos}
                            onZoomClick={this.onZoomClick}
                            archive={true}
                        />
                    </ContainerSizeSensor>
                    <ImageModal
                        ref={(elem) => { this.imageModalRef = elem }}
                        photo={this.galleryStore.selectedPhoto}
                        canDownload={true}
                        onDelete={this.onPhotoDelete} />
                    <BottomOptions
                        selectedItems={this.galleryStore.selectedPhotos}
                        onRestore={this.onRestore}
                        onDelete={this.onDeleteSelected}
                        onClear={this.onClearSelection} />
                </div>
            </Provider >
        );
    }
}

export default observer(ArchivePage)
