import React, { Component } from 'react';
import UploadImageButton from '../components/UploadImageButton';
import { getPluralForm } from '../helpers/getPluralForm';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faImages, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { BottomOptions } from '../components/BottomOptions';
import { Modal, ModalBody } from 'reactstrap';

import "./AlbumPage.css";
import ImageModal from '../components/ImageModal';
import api from '../api';
import helpers from '../helpers';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer } from 'mobx-react';
import { Loader } from '../components/Loader';

class AlbumPage extends Component {
    static displayName = AlbumPage.name;
    inicialCount = 30;
    loadMoreCount = 15;

    constructor(props) {
        super(props);

        this.albumId = this.props.match.params.albumid;
        this.galleryStore = new GalleryStore(this.albumId, false, false);

        this.state = {
            albumName: "Галерея",
            addModalShow: false,
        };

        this.onPhotoSelect = this.onPhotoSelect.bind(this);
        this.onBackClick = this.onBackClick.bind(this);
        this.onAltSelect = this.onAltSelect.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);
        this.onClearSelection = this.onClearSelection.bind(this);
        this.onDeleteSelected = this.onDeleteSelected.bind(this);
        this.onRemoveFromAlbum = this.onRemoveFromAlbum.bind(this);
        this.onPhotoDelete = this.onPhotoDelete.bind(this);
        this.imageUploaded = this.imageUploaded.bind(this);
    }

    onBackClick() {
        this.props.history.push(`/albums`);
    }

    onPhotoSelect(selectedPhoto) {
        if (this.galleryStore.selectedPhotos.length > 0) {
            this.onAltSelect(selectedPhoto);
        } else {
            this.galleryStore.selectPhoto(selectedPhoto);
            this.imageModalRef.show();
        }
    }

    onAlbumChanged(albumName) {
        this.setState({ albumName: albumName });
    }

    onAltSelect(selectedPhoto) {
        this.galleryStore.toggleMultiSelection(selectedPhoto.id);
    }

    onZoomClick(selectedPhoto) {
        this.galleryStore.selectPhoto(selectedPhoto);
        this.imageModalRef.show();
    }

    onClearSelection() {
        this.galleryStore.clearMultiSelection();
    }

    async onDeleteSelected() {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(
                await api.photos.deletePhotos(this.galleryStore.selectedPhotos),
                "Ошибка удаления фотографии");
            if (response.ok) {
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onRemoveFromAlbum() {
        if (window.confirm("Подтвердите исключение выбранных фотографий из альбома")) {
            let response = await helpers.alertError(await api.albums.removeFromAlbum(this.albumId, this.galleryStore.selectedPhotos));
            if (response.ok) {
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    async onPhotoDelete(photo) {
        if (window.confirm("Удалить фото?")) {
            let response = await helpers.alertError(await api.photos.deletePhotos([photo?.id]), "Ошибка удаления фотографии");
            if (response.ok) {
                this.imageModalRef.hide();
                this.galleryStore.refresh();
                await this.galleryStore.loadPhotos(0, this.inicialCount);
            }
        }
    }

    onAddPhotoBtnClick() {
        this.setState({ addModalShow: true });
    }

    onAddExistingClick() {
        this.props.history.push(`/addtoalbum/${this.albumId}`);
    }

    async imageUploaded() {
        this.galleryStore.refresh();
        await this.galleryStore.loadPhotos(0, this.inicialCount);
    }

    render() {
        const toggle = () => this.setState({ addModalShow: false });

        return (
            <Provider galleryStore={this.galleryStore} >
                <div className="pb-3">
                    <div className="sticky-header pb-3 pt-3 row">
                        <div className="col-xl-10 col-md-9 col-7">
                            <span className="h1 album-back-btn"
                                onClick={this.onBackClick}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} />
                            </span>
                            <span className="h1 mx-3"><Loader isLoading={this.galleryStore.loading && !this.galleryStore.totalCount}>{this.galleryStore.albumName}</Loader></span>
                            <span className="h5">
                                <Loader isLoading={this.galleryStore.loading && !this.galleryStore.totalCount}>
                                    {this.galleryStore.totalCount} {getPluralForm(this.galleryStore.totalCount, "объект", "объекта", "объектов")}
                                </Loader>
                            </span>
                        </div>
                        <div className="col-xl-2 col-md-3 col-5">
                            <button onClick={() => { this.onAddPhotoBtnClick(); }} className="btn btn-primary btn-lg w-100"><FontAwesomeIcon icon={faPlusCircle} /> Добавить</button>
                        </div>
                    </div>
                    <ImageModal
                        ref={(elem) => { this.imageModalRef = elem }}
                        photo={this.galleryStore.selectedPhoto}
                        canDownload={true}
                        onDelete={this.onPhotoDelete}
                        onMarkFavourite={async () => { await this.galleryStore.markSelectedPhotoFavourite() }} />
                    <ContainerSizeSensor>
                        <Gallery
                            onSelect={this.onPhotoSelect}
                            onTotalChanged={this.onTotalChanged}
                            albumId={this.albumId}
                            onAltSelect={this.onAltSelect}
                            selectedPhotos={this.galleryStore.selectedPhotos}
                            onZoomClick={this.onZoomClick}
                        />
                    </ContainerSizeSensor>
                    <BottomOptions
                        selectedItems={this.galleryStore.selectedPhotos}
                        onDelete={this.onDeleteSelected}
                        onRemove={this.onRemoveFromAlbum}
                        onClear={this.onClearSelection} />
                    <Modal
                        isOpen={this.state.addModalShow}
                        toggle={toggle}
                        modalTransition={{ timeout: 200 }}
                        className="modal-sm modal-dialog-centered">
                        <ModalBody>
                            <button onClick={() => { this.onAddExistingClick(); }} className="btn btn-primary btn-lg"><FontAwesomeIcon icon={faImages} /> Добавить существующие</button>
                            <p className="fs-4 text-center mb-1">или</p>
                            <UploadImageButton albumId={this.albumId} />
                        </ModalBody>
                    </Modal>
                </div>
            </Provider>
        );
    }
}

export default observer(AlbumPage)