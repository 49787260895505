import { faMagnifyingGlassPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import "./Gallery.css"
import { asJpegBlobUrl } from '../helpers/encodeBase64Url';
import { inject, observer } from "mobx-react"

class Gallery extends Component {
    static displayName = Gallery.name;
    inicialCount = 30;
    loadMoreCount = 15;

    constructor(props) {
        super(props);

        this.galleryStore = props.galleryStore;
        this.containerSizeStore = props.containerSizeStore;

        this.loadMoreOnScroll = this.loadMoreOnScroll.bind(this);
        this.onSelectPhoto = this.onSelectPhoto.bind(this);
        this.onAltSelectPhoto = this.onAltSelectPhoto.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);

        this.currentYear = new Date().getFullYear();
    }

    componentDidMount() {
        window.addEventListener('scroll', this.loadMoreOnScroll);
        this.galleryStore.loadPhotos(0, this.inicialCount);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMoreOnScroll);
    }

    async loadMoreOnScroll() {
        if (window.innerHeight + document.documentElement.scrollTop >= document.scrollingElement.scrollHeight - 400
            && !this.galleryStore.loading
            && this.galleryStore.skip < this.galleryStore.totalCount) {
            await this.galleryStore.loadPhotos(this.galleryStore.skip, this.loadMoreCount);
        }
    }

    onSelectPhoto(selectedPhoto, event) {
        if (event.defaultPrevented) {
            return;
        }
        if (this.props.onSelect) {
            this.props.onSelect(selectedPhoto);
        }
    }

    onAltSelectPhoto(selectedPhoto) {
        return (e) => {
            e.preventDefault();
            if (this.props.onAltSelect) {
                this.props.onAltSelect(selectedPhoto);
            }
        }
    }

    onZoomClick(selectedPhoto) {
        return (e) => {
            e.preventDefault();
            if (this.props.onZoomClick) {
                this.props.onZoomClick(selectedPhoto);
            }
        }
    }

    groupPhotosIntoRows(photos, windowWidth) {
        let rowWidth = 0;
        let photoRows = [];
        let photoRow = [];
        for (var i = 0; i < photos.length; i++) {
            let photo = photos[i];
            photoRow.push(photo);
            rowWidth += photo.thumbnailWidth;
            if (rowWidth > windowWidth) {
                photoRows.push(photoRow);
                photoRow = [];
                rowWidth = 0;
            }
        }
        if (photoRow) {
            photoRows.push(photoRow);
        }
        return photoRows;
    }

    groupPhotosIntoMonths(photos) {
        let keyDate = (m) => { return `${m.getFullYear()}-${m.getMonth()+1}-1`; }

        let groupedPhotos = photos.reduce((acc, value) => {
            if (!acc.has(keyDate(value.creationTime))) {
                acc.set(keyDate(value.creationTime), []);
            }
            acc.get(keyDate(value.creationTime)).push(value);
            return acc;
        }, new Map());

        var res = [];
        groupedPhotos.forEach((v, k) => {
            res.push({
                groupDate: new Date(k),
                groupValue: this.groupPhotosIntoRows(v, this.containerSizeStore.windowWidth)
            });
        });
        return res;
    }

    getSelectedAttrValue(photoId) {
        return (this.galleryStore.selectedPhotos && this.galleryStore.selectedPhotos.includes(photoId) ? "true" : undefined);
    }

    getDateBlock(groupDate) {
        return (<div className="fs-5 py-2 ps-2">
            <span className="text-capitalize">{groupDate.toLocaleDateString("ru-Ru", { month: 'long' })}</span>
            <span>{groupDate.getFullYear() !== this.currentYear ? ` ${groupDate.getFullYear()} г.` : undefined}</span>
        </div>)
    }

    render() {
        var photos = this.groupPhotosIntoMonths(this.galleryStore.photos);

        return (
            <div>
                {photos.map((photoRows) =>
                    <div key={photoRows.groupDate}>
                        {this.getDateBlock(photoRows.groupDate)}
                        <div className="flex flex-wrap" show-zoom={this.galleryStore.selectedPhotos?.length > 0 ? "true" : undefined}>
                            {photoRows.groupValue.map((row, i) =>
                                <div key={i} className="picture-row">
                                    {row.map(photo =>
                                        <div
                                            onClick={(e) => this.onSelectPhoto(photo, e)}
                                            key={photo.id}
                                            className="picture-container"
                                            style={{ width: photo.thumbnailWidth }}
                                            picture-item-selected={this.getSelectedAttrValue(photo.id)}
                                        >
                                            <div
                                                className="picture-item"
                                                style={{ backgroundImage: asJpegBlobUrl(photo.thumbnail), width: photo.thumbnailWidth }}>
                                            </div>
                                            {photo.isFavourite ? <div onClick={this.onZoomClick(photo)} className="picture-item-favourite"><FontAwesomeIcon icon={faStar} /></div> : undefined}
                                            {this.props.onZoomClick ? <div onClick={this.onZoomClick(photo)} className="picture-item-zoom"><FontAwesomeIcon icon={faMagnifyingGlassPlus} /></div> : undefined}
                                            {this.props.onAltSelect ? <div onClick={this.onAltSelectPhoto(photo)} className="picture-item-altselect">
                                                <svg className="svg-inline--fa fa-circle-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <path fill="currentColor" d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                                                    <path id="innerPath" fill="none" d="M371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"></path>
                                                </svg>
                                            </div> : undefined}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {this.galleryStore.loading
                    ? <div className="d-flex justify-content-center my-3">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div> : undefined}
            </div>
        );
    }
}

export default inject('galleryStore', 'containerSizeStore')(observer(Gallery))