import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { getPluralForm } from '../helpers/getPluralForm';
import Albums from '../components/Albums';
import "./AlbumsPage.css"
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import api from '../api';
import helpers from '../helpers';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer, inject } from 'mobx-react';
import AlbumsPageStore from '../stores/albumsPageStore';
import { Loader } from '../components/Loader';

class AlbumsPage extends Component {
    static displayName = AlbumsPage.name;

    constructor(props) {
        super(props);

        this.galleryStore = new GalleryStore(null, false, false);
        this.albumsPageStore = new AlbumsPageStore();
        this.albumsStore = props.albumsStore;

        this.onAlbumClick = this.onAlbumClick.bind(this);
        this.onAddAlbum = this.onAddAlbum.bind(this);
        this.onAlbumDelete = this.onAlbumDelete.bind(this);
        this.onAlbumRename = this.onAlbumRename.bind(this);
        this.onRenameClick = this.onRenameClick.bind(this);
        this.onCoverChange = this.onCoverChange.bind(this);
        this.onChangeCoverClick = this.onChangeCoverClick.bind(this);
    }

    onAlbumClick(album) {
        this.props.history.push(`/album/${album.id}`);
    }

    onAddAlbum() {
        this.props.history.push(`/createalbum`);
    }

    async onAlbumDelete(album) {
        if (window.confirm(`Подтвердите удаление альбома`)) {
            let response = await helpers.alertError(await api.albums.deleteAlbum(album.id));
            if (response.ok) {
                await this.albumsStore.loadAlbums();
            }
        }
    }

    onAlbumRename(album) {
        this.albumsPageStore.selectRenameAlbum(album);
        this.albumsPageStore.toggleRenameModal(true);
    }

    async onRenameClick() {
        let response = await helpers.alertError(await api.albums.updateAlbum(
            this.albumsPageStore.renameAlbum.id,
            this.albumsPageStore.renameAlbum.name,
            this.albumsPageStore.renameAlbum.description));
        if (response.ok) {
            this.albumsPageStore.toggleRenameModal(false);
            await this.albumsStore.loadAlbums();
        }
    }

    onCoverChange(album) {
        this.galleryStore.setAlbumId(album.id);
        this.galleryStore.refresh();
        this.albumsPageStore.toggleCoverModal(true);
    }

    async onChangeCoverClick(photo) {
        let response = await helpers.alertError(await api.albums.changeAlbumCover(this.galleryStore.albumId, photo.id));
        if (response.ok) {
            this.albumsPageStore.toggleCoverModal(false);
            await this.albumsStore.loadAlbums();
        }
    }

    render() {
        return (
            <Provider galleryStore={this.galleryStore}>
                <div className="pb-3">
                    <div className="sticky-header pb-3 pt-3 row">
                        <div className="col-xl-10 col-md-9 col-7">
                            <span id="tabelLabel" className="h1 me-3">Фото альбомы</span>
                            <span className="h5">
                                <Loader isLoading={this.albumsStore.loading}>
                                    {this.albumsStore.albums.length} {getPluralForm(this.albumsStore.albums.length, "объект", "объекта", "объектов")}
                                </Loader>
                            </span>
                        </div>
                        <div className="col-xl-2 col-md-3 col-5">
                            <button onClick={this.onAddAlbum} className="btn btn-primary btn-lg w-100"><FontAwesomeIcon icon={faImages} /> Новый</button>
                        </div>
                    </div>
                    <Albums
                        onAlbumClick={this.onAlbumClick}
                        showOptions={true}
                        onAlbumRename={this.onAlbumRename}
                        onAlbumDelete={this.onAlbumDelete}
                        onCoverChange={this.onCoverChange}
                    />
                    <Modal
                        isOpen={this.albumsPageStore.isRenameModalShown}
                        toggle={() => { this.albumsPageStore.toggleRenameModal(false) }}
                        modalTransition={{ timeout: 200 }}
                        className="modal-sm modal-dialog-centered">
                        {/*<ModalHeader toggle={toggle}>Выберите существующий альбом или введите название нового альбома</ModalHeader>*/}
                        <ModalBody>
                            <div className="mb-3">
                                <label className="form-label">Название</label>
                                <input
                                    value={this.albumsPageStore.renameAlbum?.name}
                                    onChange={(e) => { this.albumsPageStore.changeAlbumInfo(e.target.value, null) }}
                                    type="text"
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Описание</label>
                                <textarea
                                    value={this.albumsPageStore.renameAlbum?.description}
                                    onChange={(e) => { this.albumsPageStore.changeAlbumInfo(null, e.target.value) }}
                                    className="form-control"
                                    rows="3"
                                ></textarea>
                            </div>
                            <div className="mb-1">
                                <button onClick={this.onRenameClick} className="btn btn-primary w-100">Изменить</button>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        isOpen={this.albumsPageStore.isCoverModalShown}
                        toggle={() => { this.albumsPageStore.toggleCoverModal(false) }}
                        modalTransition={{ timeout: 200 }}
                        className="modal-xl modal-dialog-centered">
                        <ModalHeader toggle={() => { this.albumsPageStore.toggleCoverModal(false) }}>Выберите изображение на обложку</ModalHeader>
                        <ModalBody>
                            <ContainerSizeSensor>
                                <Gallery
                                    onSelect={this.onChangeCoverClick}
                                />
                            </ContainerSizeSensor>
                        </ModalBody>
                    </Modal>
                </div>
            </Provider >
        );
    }
}

export default inject('albumsStore')(observer(AlbumsPage))