import { action, makeObservable, observable } from 'mobx';
import helpers from '../helpers';
import api from '../api';

export default class AppStore {
    constructor() {
        makeObservable(this, {
            toasts: observable,
            progressToasts: observable,

            upload: action,
            uploadImage: action,
            createToast: action,
            onCloseToast: action,
            onCloseProgressToast: action,
        });
    }

    toasts = [];
    progressToasts = [];
    numerator = 0;
    isActiveUpload = false;
    afterUpload = null;
    albumId = null;

    async uploadImage(e) {
        this.isActiveUpload = true;
        let filesCount = e.target.files.length;
        let progressToast = {
            id: this.numerator++,
            current: 0,
            total: filesCount,
            succesfull: 0,
        }
        makeObservable(progressToast, { current: observable });
        this.progressToasts = this.toasts.concat([progressToast]);
        for (var i = 0; i < filesCount; i++) {
            progressToast.current = i;
            let file = e.target.files[i];
            if (file.type !== "image/jpeg" && file.type !== "image/png") {
                console.log(`Файл ${file.name} не является изображением в фотмате jpg или png`);
                this.createToast({ head: 'Ошибка', body: `Файл ${file.name} не является изображением в фотмате jpg или png` });
                continue;
            }
            if (file.size > 1024 * 1024 * 10) {
                console.log(`Файл ${file.name} весит больше 10МБ`);
                this.createToast({ head: 'Ошибка', body: `Файл ${file.name} весит больше 10МБ` });
                continue;
            }
            let response = await helpers.alertError(await api.photos.uploadPhotos(file, this.albumId));
            if (response.ok) {
                progressToast.succesfull += 1;
            }
        }
        progressToast.current = progressToast.total;

        e.target.value = null;
        this.isActiveUpload = false;
        await this.afterUpload();
    }

    createToast(toast) {
        toast.id = this.numerator++;
        this.toasts = this.toasts.concat([toast]);
    }

    onCloseToast(toastId) {
        this.toasts = this.toasts.filter(m => m.id !== toastId);
    }

    onCloseProgressToast(toastId) {
        this.progressToasts = this.progressToasts.filter(m => m.id !== toastId);
    }

    upload(albumId, afterUpload) {
        if (this.isActiveUpload) {
            return;
        }
        this.albumId = albumId;
        this.afterUpload = afterUpload;
        document.getElementById("imageUploader").click();
    }
}