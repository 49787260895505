import { faFolderMinus, faFolderPlus, faTrash, faEyeSlash, faEye, faStar, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { getPluralForm } from '../helpers/getPluralForm';
import "./BottomOptions.css"

export class BottomOptions extends Component {
    static displayName = BottomOptions.name;

    render() {
        return (
            <div className="fixed-bottom border-top bottom-options"
                invisible={this.props.selectedItems.length === 0 ? "true" : undefined}>
                <div className="container bg-body">
                    <div className="w-100 d-inline-flex py-1 align-items-center">
                        {this.props.onClear ? <button onClick={this.props.onClear} type="button" className="btn-close"></button> : undefined}
                        <p className="me-auto ms-1 my-auto fs-5">Выбрано: {this.props.selectedItems.length} {getPluralForm(this.props.selectedItems.length, "объект", "объекта", "объектов")}</p>
                        <div className="d-none d-lg-block">
                            {this.props.onMarkFavourite ? <button onClick={this.props.onMarkFavourite} className="btn btn-warning mx-1"><FontAwesomeIcon icon={faStar} /> Избранное</button> : undefined}
                            {this.props.onRemoveFromFavourites ? <button onClick={this.props.onRemoveFromFavourites} className="btn btn-warning mx-1"><FontAwesomeIcon icon={faStar} /> Убрать из избранного</button> : undefined}
                            {this.props.onArchive ? <button onClick={this.props.onArchive} className="btn btn-warning mx-1"><FontAwesomeIcon icon={faEyeSlash} /> В архив</button> : undefined}
                            {this.props.onRestore ? <button onClick={this.props.onRestore} className="btn btn-warning mx-1"><FontAwesomeIcon icon={faEye} /> Восстановить</button> : undefined}
                            {this.props.onAdd ? <button onClick={this.props.onAdd} className="btn btn-primary mx-1"><FontAwesomeIcon icon={faFolderPlus} /> Добавить в альбом</button> : undefined}
                            {this.props.onRemove ? <button onClick={this.props.onRemove} className="btn btn-primary mx-1"><FontAwesomeIcon icon={faFolderMinus} /> Убрать из альбома</button> : undefined}
                            {this.props.onDelete ? <button onClick={this.props.onDelete} className="btn btn-danger mx-1"><FontAwesomeIcon icon={faTrash} /> Удалить</button> : undefined}
                        </div>
                        <div className="dropdown d-lg-none">
                            <div className="btn btn-secondary" type="button" id="bottomOptionsButton" data-bs-toggle="dropdown" aria-expanded="false">
                                Выбрать действие <FontAwesomeIcon icon={faChevronUp} />
                            </div>
                            <ul className="dropdown-menu" aria-labelledby="bottomOptionsButton">
                                {this.props.onMarkFavourite
                                    ? <li><button className="dropdown-item" onClick={this.props.onMarkFavourite}><FontAwesomeIcon icon={faStar} /> Избранное</button></li>
                                    : undefined}
                                {this.props.onRemoveFromFavourites
                                    ? <li><button className="dropdown-item" onClick={this.props.onRemoveFromFavourites}><FontAwesomeIcon icon={faStar} /> Убрать из избранного</button></li>
                                    : undefined}
                                {this.props.onArchive
                                    ? <li><span className="dropdown-item" onClick={this.props.onArchive}><FontAwesomeIcon icon={faEyeSlash} /> В архив</span></li>
                                    : undefined}
                                {this.props.onRestore
                                    ? <li><span className="dropdown-item" onClick={this.props.onRestore}><FontAwesomeIcon icon={faEye} /> Восстановить</span></li>
                                    : undefined}
                                {this.props.onAdd
                                    ? <li><span className="dropdown-item" onClick={this.props.onAdd}><FontAwesomeIcon icon={faFolderPlus} /> Добавить в альбом</span></li>
                                    : undefined}
                                {this.props.onRemove
                                    ? <li><span className="dropdown-item" onClick={this.props.onRemove}><FontAwesomeIcon icon={faFolderMinus} /> Убрать из альбома</span></li>
                                    : undefined}
                                {this.props.onDelete
                                    ? <li><span className="dropdown-item" onClick={this.props.onDelete}><FontAwesomeIcon icon={faTrash} /> Удалить</span></li>
                                    : undefined}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
