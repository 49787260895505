import React, { Component } from 'react';
import ContainerSizeStore from '../stores/containerSizeStore';
import { Provider } from 'mobx-react';

class ContainerSizeSensor extends Component {
    static displayName = ContainerSizeSensor.name;

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.containerSizeStore = new ContainerSizeStore();
        this.handleResize = this.handleResize.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    }

    handleResize() {
        let cw = this.myRef.current.clientWidth;
        let ch = this.myRef.current.clientHeight;
        this.containerSizeStore.setWindowSize(cw, ch);
    }

    render() {
        return (
            <div className={ this.props.cn } ref={this.myRef}>
                <Provider containerSizeStore={this.containerSizeStore}>
                    {this.props.children}
                </Provider>
            </div>
        );
    }
}

export default ContainerSizeSensor