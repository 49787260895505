import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from "mobx-react"

class UploadImageButton extends Component {
    static displayName = UploadImageButton.name;
    inicialCount = 30;

    constructor(props) {
        super(props);
        this.galleryStore = props.galleryStore;
        this.appStore = props.appStore;
        this.afterUpload = this.afterUpload.bind(this);
    }

    async onBtnClick() {
        this.appStore.upload(this.props.albumId, this.afterUpload);
    }

    async afterUpload() {
        this.galleryStore.refresh();
        await this.galleryStore.loadPhotos(0, this.inicialCount);
    }

    render() {
        return (
            <>
                <button className="btn btn-primary btn-lg w-100" onClick={() => { this.onBtnClick() }}><FontAwesomeIcon icon={faCloudUpload} /> Загрузить</button>
            </>
        );
    }
}

export default inject("galleryStore", "appStore")(observer(UploadImageButton))