import React, { Component } from 'react';
import "./Loader.css"

export class Loader extends Component {
    static displayName = Loader.name;

    render() {
        return (
            <span className="loader" isloading={this.props.isLoading ? "true" : undefined}>
                {this.props.isLoading
                    ? 'x'
                    : this.props.children}
            </span>
        );
    }
}
