import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ImageFull from '../components/ImageFull';
import "./ImageModal.css"
import { inject, observer } from "mobx-react"

class ImageModal extends Component {
    static displayName = ImageModal.name;

    constructor(props) {
        super(props);
        this.galleryStore = props.galleryStore;
        this.state = { show: false };
        this.hide = this.hide.bind(this);
    }

    hide() {
        this.setState({ show: false });
        document.body.style.overflow = "visible";
        if (this.props.onHide) {
            this.props.onHide();
        }
    }

    show() {
        this.setState({ show: true });
        document.body.style.overflow = "hidden";
    }

    render() {
        return (
            <Modal
                isOpen={this.state.show}
                toggle={this.hide}
                modalTransition={{ timeout: 200 }}
                onOpened={() => { this.imgFullRef.loadImage() }}
                className="modal-fullscreen modal-dialog-centered">
                <ModalBody className="modal-body-dark">
                    {this.galleryStore.selectedPhoto
                        ? <ImageFull
                            ref={elem => this.imgFullRef = elem}
                            hideModal={this.hide}
                            onDelete={this.props.onDelete}
                            onMarkFavourite={this.props.onMarkFavourite}
                            canDownload={this.props.canDownload}
                            onSelectNeighbor={this.props.onSelectNeighbor} />
                        : <div></div>}
                </ModalBody>
            </Modal>
        );
    }
}

export default inject('galleryStore')(observer(ImageModal))