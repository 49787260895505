import { action, makeObservable, observable, runInAction } from 'mobx';

export default class AlbumsPageStore {
    constructor() {
        makeObservable(this, {
            isRenameModalShown: observable,
            isCoverModalShown: observable,
            renameAlbum: observable,
            coverAlbumId: observable,

            selectRenameAlbum: action,
            changeAlbumInfo: action,
            toggleRenameModal: action,
            toggleCoverModal: action,
        });
    }

    isRenameModalShown = false;
    isCoverModalShown = false;
    renameAlbum = null; //альбом для переименования
    coverAlbumId = null; //id альбома для смены обложки

    selectRenameAlbum = (album) => {
        this.renameAlbum = {
            id: album.id,
            name: album.name,
            description: album.description
        }
    }

    changeAlbumInfo = (name, description) => {
        console.log(name, description);
        if (typeof(name) === 'string') {
            this.renameAlbum.name = name;
        }
        if (typeof (description) === 'string') {
            this.renameAlbum.description = description;
        }
    }

    toggleRenameModal = (value) => {
        this.isRenameModalShown = value;
    }

    toggleCoverModal = (value) => {
        this.isCoverModalShown = value;
    }
}