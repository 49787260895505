export function asJpegBlob(base64) {
    return `data:image/jpeg;base64,${base64}`;
}

export function asJpegBlobUrl(base64) {
    return asUrl(asJpegBlob(base64));
}

export function asUrl(str) {
    return `url(${str})`;
}