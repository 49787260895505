import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

class ZoomablePhoto extends Component {
    static displayName = ZoomablePhoto.name;

    constructor(props) {
        super(props);
        this.containerSizeStore = props.containerSizeStore;
        this.zoomablePhotoStore = props.zoomablePhotoStore;
        this.galleryStore = props.galleryStore;
        this.state = { mouseDown: false };
        this.setBlockAndImageSizes = this.setBlockAndImageSizes.bind(this);
    }

    componentDidMount() {
        setTimeout(this.setBlockAndImageSizes, 0);
    }

    componentDidUpdate() {
        this.setBlockAndImageSizes();
    }

    setBlockAndImageSizes() {
        let baseHeight = this.containerSizeStore.windowWidth / this.containerSizeStore.windowHeight > this.galleryStore.selectedPhoto?.width / this.galleryStore.selectedPhoto?.height
            ? this.containerSizeStore.windowHeight
            : this.containerSizeStore.windowWidth / this.galleryStore.selectedPhoto?.width * this.galleryStore.selectedPhoto?.height;
        this.zoomablePhotoStore.onSetBlockAndImage(this.containerSizeStore.windowWidth, this.containerSizeStore.windowHeight, this.galleryStore.selectedPhoto?.width, this.galleryStore.selectedPhoto?.height, baseHeight);
    }

    onMouseDown(e) {
        this.setState({ mouseDown: true, posX: e.clientX, posY: e.clientY });
    }

    onMouseLeave() {
        this.setState({ mouseDown: false });
    }

    onMouseUp() {
        this.setState({ mouseDown: false });
    }

    onMouseMove(e) {
        if (this.state.mouseDown === true) {
            let moveX = this.state.posX - e.clientX;
            let moveY = this.state.posY - e.clientY;
            this.zoomablePhotoStore.onPerformMove(moveX, moveY);
            this.setState({ posX: e.clientX, posY: e.clientY });
        }
    }

    render() {
        return (
            <div className="bg-photo"
                onMouseDown={(e) => { this.onMouseDown(e); }}
                onMouseUp={(e) => { this.onMouseUp(e); }}
                onMouseMove={(e) => { this.onMouseMove(e); }}
                onMouseLeave={(e) => { this.onMouseLeave(e); }}
                style={{
                    backgroundImage: this.props.bgImage,
                    backgroundSize: "auto " + this.zoomablePhotoStore.baseHeight * this.zoomablePhotoStore.zoom + "px",
                    backgroundPositionX: this.zoomablePhotoStore.posX + "px",
                    backgroundPositionY: this.zoomablePhotoStore.posY + "px"
                }}>
            </div>
        );
    }
}

export default inject('zoomablePhotoStore', 'containerSizeStore', 'galleryStore')(observer(ZoomablePhoto))
