import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { YMaps, Map, Placemark, ObjectManager } from 'react-yandex-maps';
import MapStore from '../stores/mapStore';

class MapPage extends Component {
    static displayName = MapPage.name;

    constructor(props) {
        super(props);
        this.mapStore = new MapStore();
        this.mapStore.loadPhotos();
    }

    render() {
        let x = this.mapStore.avgPosX;
        let y = this.mapStore.avgPosY;
        let zoom = 12;

        return (
            <div className="row">
                <div className="col-md-9">
                    <YMaps>
                        <Map
                            width="100%"
                            height="800px"
                            defaultState={{ center: [x, y], zoom: zoom }}
                            state={{ center: [x, y], zoom: zoom }}
                        >
                            <ObjectManager
                                options={{
                                    clusterize: true,
                                }}
                                objects={{
                                    preset: "islands#redIcon",
                                }}
                                clusters={{
                                    preset: "islands#redClusterIcons",
                                }}
                                defaultFeatures={this.mapStore.photos.map(m => ({
                                    type: "Feature",
                                    id: m.id,
                                    geometry: {
                                        type: "Point",
                                        coordinates: [m.latitude, m.longitude],
                                    },
                                }
                                ))}
                                instanceRef={(ref) => ref?.objects.events.add('click', el => console.log(el._sourceEvent.originalEvent.objectId))}
                            />
                        </Map>
                    </YMaps>
                </div>
                <div className="col-md-3">
                    {this.mapStore.photos.map(m =>
                        <div key={m.id} className="card">
                            <img className="card-img-top" src={`https://localhost:44447/api/photos/GetPhotoThumbFile?photoId=${m.id}`} alt="Фото" />
                            <div className="card-body">
                                <h5 className="card-title">{m.fileName}</h5>
                            </div>
                        </div>)}
                </div>
            </div>
        );
    }
}

export default inject('userStore')(observer(MapPage));