import React, { Component } from 'react';

export class HomePage extends Component {
    static displayName = HomePage.name;

    render() {
        return (
            <div>
                <h1>Добро пожаловать в мою фото галерею!</h1>
                <p>Это приватная галерея с открытым исходным кодом (будет открыт позже) без возможности регистрации сторонних пользователей.<br/> Проект используетс следующие решения:</p>
                <ul>
                    <li><a href='https://get.asp.net/'>ASP.NET Core</a> и <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> для разработки кросплатформенного бэкенда</li>
                    <li><a href='https://facebook.github.io/react/'>React</a> для фронтенда</li>
                    <li><a href='https://getbootstrap.com/'>Bootstrap</a> и немного <em>css</em> говнокода для вёрстки</li>
                    <li><a href='https://www.python.org/'>Python</a> и <a href='https://github.com/OlafenwaMoses/ImageAI/'>ImageAI</a> для поиска объектов на фотографиях</li>
                </ul>
                <p>Сейчас функционала не очень много, но весь базовый присутствует.<br /> В ближайшем будущем планируется добавление следующих возможностей:</p>
                <ul>
                    <li><del>Отправка фотографий в архив</del></li>
                    <li>Удаление фотографий с диска через период после отметки об удалении фотографии в базе (отдельный сервис?)</li>
                    <li><del>Отметка избранных фотографий</del></li>
                    <li><del>Смена обложки альбома при исключении картинки</del></li>
                    <li><del>Смена пароля пользователем</del></li>
                    <li><del>Нормальная обработка ошибок с информированием тостами на фронте</del></li>
                    <li><del>Хранение изображений на диске по id вместо имени</del></li>
                    <li><del>Проставление на фотографиях тегов нейронной сетью по распознаванию объектов (отдельный сервис)</del></li>
                    <li>Возможность искать фотографии по тегам</li>
                    <li><del>Возможность посмотреть места фотографий на карте</del></li>
                    <li>Возможность делиться альбомами с другими пользователями</li>
                    <li><del>Возможность листать фотографии</del></li>
                    <li>Админка</li>
                </ul>
                <p>Совсем далёкие хотелки:</p>
                <ul>
                    <li>Демо режим</li>
                    <li>Приложение под андроид, если хватит желания (надо брата запрячь)</li>
                    <li>Интеграция с Google Photo</li>
                </ul>
                <p>Разработчик - <strong><a href='https://github.com/Belsev/'>Belsev</a></strong></p>
            </div>
        );
    }
}
