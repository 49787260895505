import { action, makeObservable, observable, runInAction } from 'mobx';
import api from '../api';

export default class UserStore {
    constructor() {
        makeObservable(this, {
            userName: observable,
            getCurrentUser: action,
        });
    }

    userName = false;

    changeUser = (userName) => {
        this.userName = userName;
    }

    getCurrentUser = async () => {
        let response = await await api.users.isSignedIn();
        if (!response.ok) {
            return false;
        }
        let text = await response.text();
        runInAction(() => {
            this.userName = text;
        })
        return true;
    }
}