import React, { Component } from 'react';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import ImageModal from '../components/ImageModal';
import api from '../api';
import helpers from '../helpers';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer } from 'mobx-react';

class CreateAlbumPage extends Component {
    static displayName = CreateAlbumPage.name;
    inicialCount = 30;

    constructor(props) {
        super(props);

        this.galleryStore = new GalleryStore(null, false, false);

        this.onPhotoSelect = this.onPhotoSelect.bind(this);
        this.onCreateClick = this.onCreateClick.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.state = {
            albumName: "",
        }
    }

    onNameChange(e) {
        this.setState({ albumName: e.target.value });
    }

    onPhotoSelect(selectedPhoto) {
        this.galleryStore.toggleMultiSelection(selectedPhoto.id);
    }

    onZoomClick(selectedPhoto) {
        this.galleryStore.selectPhoto(selectedPhoto);
        this.imageModalRef.show();
    }

    async onCreateClick() {
        if (this.galleryStore.selectedPhotos.length === 0) {
            window.alert("Необходимо выбрать хотя бы одну фотографию");
            return;
        }
        if (this.state.albumName.length === 0) {
            window.alert("Введите название альбома");
            return;
        }
        let response = await helpers.alertError(await api.albums.createAlbum(this.state.albumName, "", this.galleryStore.selectedPhotos, this.galleryStore.selectedPhotos[0]));
        if (response.ok) {
            let albumId = await response.text();
            this.props.history.push(`/album/${albumId}`);
        }
    }

    render() {
        return (
            <Provider galleryStore={this.galleryStore}>
                <div className="mb-3">
                    <ImageModal ref={(elem) => { this.imageModalRef = elem }} />
                    <div className="sticky-header pb-3 pt-3 row">
                        <div className="col-xl-7 col-md-5 col-12">
                            <input onChange={this.onNameChange} type="text" className="form-control-plaintext form-control-lg col-6" placeholder="Название альбома" />
                        </div>
                        <label className="col-xl-3 col-md-4 col-6 col-form-label col-form-label-lg">Выбрано фотографий: {this.galleryStore.selectedPhotos.length}</label>
                        <div className="col-xl-2 col-md-3 col-6">
                            <button onClick={this.onCreateClick} className="btn btn-primary btn-lg w-100" ><FontAwesomeIcon icon={faImages} /> Создать</button>
                        </div>
                    </div>
                    <ContainerSizeSensor>
                        <Gallery
                            onSelect={this.onPhotoSelect}
                            selectedPhotos={this.galleryStore.selectedPhotos}
                            onZoomClick={this.onZoomClick}
                            onAltSelect={this.onPhotoSelect}
                        />
                    </ContainerSizeSensor>
                </div>
            </Provider>
        );
    }
}

export default observer(CreateAlbumPage)