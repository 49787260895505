import { action, makeObservable, observable } from 'mobx';

export default class ContainerSizeStore {
    constructor() {
        makeObservable(this, {
            windowWidth: observable,
            windowHeight: observable,

            setWindowSize: action,
        });
    }

    windowWidth = 0;
    windowHeight = 0;

    setWindowSize(width, height) {
        this.windowWidth = width;
        this.windowHeight = height;
    }
}
