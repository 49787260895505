import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { HomePage } from './pages/HomePage';
import GalleryPage from './pages/GalleryPage';
import LoginPage from './pages/LoginPage';
import CreateAlbumPage from './pages/CreateAlbumPage';
import AddPhotosPage from './pages/AddPhotosPage';
import AlbumsPage from './pages/AlbumsPage';
import AlbumPage from './pages/AlbumPage';
import UserPage from './pages/UserPage';
import ArchivePage from './pages/ArchivePage';
import FavouritesPage from './pages/FavouritesPage';
import MapPage from './pages/MapPage';
import UserStore from './stores/userStore';
import AlbumsStore from './stores/albumsStore';
import { Provider } from 'mobx-react';
import './custom.css'
import { withRouter } from 'react-router-dom';
import AppStore from './stores/appStore';
import { observer } from "mobx-react"
import Consts from './helpers/consts'

class App extends Component {
    static displayName = App.name;

    async componentDidMount() {
        let isLogged = await this.userStore.getCurrentUser();
        if (!isLogged) {
            if (window.location.pathname !== "/") {
                window.location.href = `${Consts.loginUrl}?returnUrl=${encodeURIComponent(document.URL)}`
            }
        }
    }

    constructor(props) {
        super(props);

        this.userStore = new UserStore();
        this.albumsStore = new AlbumsStore();
        this.appStore = new AppStore();

        //Немного костыль, но пока так
        //todo: исправить. 
        document.notify = (toast) => this.appStore.createToast(toast);
    }

    render() {
        return (
            <>
                <Provider userStore={this.userStore} albumsStore={this.albumsStore} appStore={this.appStore}>
                    <Layout>
                        <Route exact path='/' component={HomePage} />
                        <Route path='/album/:albumid' render={(props) => (<AlbumPage key={props.match.params.albumid} {...props} />)} />
                        <Route path='/gallery' component={GalleryPage} />
                        <Route path='/albums' component={AlbumsPage} />
                        <Route path='/createalbum' component={CreateAlbumPage} />
                        <Route path='/login' component={LoginPage} />
                        <Route path='/addtoalbum/:albumid' component={AddPhotosPage} />
                        <Route path='/user' component={UserPage} />
                        <Route path='/archive' component={ArchivePage} />
                        <Route path='/favourites' component={FavouritesPage} />
                        <Route path='/map' component={MapPage} />
                    </Layout>

                    <input className="visually-hidden" id="imageUploader" type='file' accept="image/png, image/jpeg" onInput={(e) => { console.log(e) }} onChange={(e) => { this.appStore.uploadImage(e) }} multiple />
                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                        {this.appStore.toasts.map(toast =>
                            <div key={toast.id} className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                                <div className="toast-header">
                                    {/*<img src="..." className="rounded me-2" alt="..." />*/}
                                    <strong className="me-auto">{toast.head}</strong>
                                    {/*<small>11 mins ago</small>*/}
                                    <button onClick={() => { this.appStore.onCloseToast(toast.id) }} type="button" className="btn-close" aria-label="Close"></button>
                                </div>
                                <div className="toast-body">
                                    {toast.body}
                                </div>
                            </div>)}
                        {this.appStore.progressToasts.map(toast => <div key={toast.id} className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                {/*<img src="..." className="rounded me-2" alt="..." />*/}
                                <strong className="me-auto">Загрузка фотографий</strong>
                                {/*<small>11 mins ago</small>*/}
                                <button onClick={() => { this.appStore.onCloseProgressToast(toast.id) }} type="button" className="btn-close" aria-label="Close"></button>
                            </div>
                            <div className="toast-body">
                                {(toast.current !== toast.total)
                                    ? <div className="mb-2">Загрузка {toast.current + 1} из {toast.total}</div>
                                    : <div className="mb-2">Успешно загружено {toast.succesfull} из {toast.total}</div>}
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" style={{ width: `${toast.current / toast.total * 100}%` }}></div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </Provider>
            </>
        );
    }
}

export default withRouter(observer(App));