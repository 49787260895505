import React, { Component } from 'react';
import Gallery from '../components/Gallery';
import ContainerSizeSensor from '../components/ContainerSizeSensor';
import ImageModal from '../components/ImageModal';
import { BottomOptions } from '../components/BottomOptions';
import api from '../api';
import helpers from '../helpers';
import GalleryStore from '../stores/galleryStore';
import { Provider, observer } from 'mobx-react';

class AddPhotosPage extends Component {
    static displayName = AddPhotosPage.name;
    inicialCount = 30;

    constructor(props) {
        super(props);

        this.galleryStore = new GalleryStore(null, false, false);

        this.state = {
            albumName: "",
        }
        this.albumId = props.match.params.albumid;

        this.onPhotoSelect = this.onPhotoSelect.bind(this);
        this.onZoomClick = this.onZoomClick.bind(this);
        this.onAddPhotos = this.onAddPhotos.bind(this);
        this.onClearSelection = this.onClearSelection.bind(this);
    }

    async componentDidMount() {
        let response = await helpers.alertError(await api.albums.getAlbumShort(this.albumId));
        if (response.ok) {
            let data = await response.json();
            this.setState({ albumName: data.albumName });
        }
    }

    onPhotoSelect(selectedPhoto) {
        this.galleryStore.toggleMultiSelection(selectedPhoto.id);
    }

    onZoomClick(selectedPhoto) {
        this.galleryStore.selectPhoto(selectedPhoto);
        this.imageModalRef.show();
    }

    async onAddPhotos() {
        let response = await helpers.alertError(await api.albums.addToAlbum(this.albumId, this.galleryStore.selectedPhotos));
        if (response.ok) {
            this.props.history.push(`/album/${this.albumId}`);
        }
    }

    onClearSelection() {
        this.galleryStore.clearMultiSelection();
    }

    render() {
        return (
            <Provider galleryStore={this.galleryStore} >
                <div className="mb-3">
                    <ImageModal ref={(elem) => { this.imageModalRef = elem }} photo={this.galleryStore.selectedPhoto} />
                    <div className="sticky-header pb-2 pt-3">
                        <h1>Выберите фотографии для добавления в альбом</h1>
                    </div>
                    <ContainerSizeSensor>
                        <Gallery
                            onSelect={this.onPhotoSelect}
                            selectedPhotos={this.galleryStore.selectedPhotos}
                            onZoomClick={this.onZoomClick}
                            onAltSelect={this.onPhotoSelect}
                        />
                    </ContainerSizeSensor>
                    <BottomOptions
                        selectedItems={this.galleryStore.selectedPhotos}
                        onAdd={this.onAddPhotos}
                        onClear={this.onClearSelection} />
                </div>
            </Provider>
        );
    }
}

export default observer(AddPhotosPage)