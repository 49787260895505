import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faCalendar, faPortrait, faBorderAll, faFileImage, faStar, faXmark, faChevronLeft, faChevronRight, faTags, faMagnifyingGlass, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faStar as faRegStar, faTrashCan, faCircleDown } from '@fortawesome/free-regular-svg-icons'
import React, { Component } from 'react';
import { formatFileSize } from '../helpers/formatFileSize';
import { asJpegBlobUrl, asUrl } from '../helpers/encodeBase64Url';
import "./ImageFull.css"
import { inject, observer } from "mobx-react"
import ZoomablePhoto from './ZoomablePhoto';
import ZoomablePhotoStore from '../stores/zoomablePhotoStore';
import { Provider } from 'mobx-react';
import ContainerSizeSensor from './ContainerSizeSensor';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

class ImageFull extends Component {
    static displayName = ImageFull.name;

    constructor(props) {
        super(props);
        this.zoomablePhotoStore = new ZoomablePhotoStore();
        this.galleryStore = props.galleryStore;
        this.galleryStore.setSelectedPhotoSrc(asJpegBlobUrl(this.galleryStore.selectedPhoto.thumbnail));
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown)
    }

    onKeyDown(e) {
        if (e.key === "ArrowRight") {
            this.onNextClick();
            return;
        }
        if (e.key === "ArrowLeft") {
            this.onPrevClick();
            return;
        }
    }

    loadImage() {
        var downloadingImage = new Image();
        let selectedPhotoId = this.galleryStore.selectedPhoto?.id;
        downloadingImage.src = 'api/photos/GetPhotoFile?photoId=' + selectedPhotoId;
        downloadingImage.onload = () => {
            if (this.galleryStore.selectedPhoto.id === selectedPhotoId) {
                this.galleryStore.setSelectedPhotoSrc(`${asUrl(downloadingImage.src)}, ${asJpegBlobUrl(this.galleryStore.selectedPhoto.thumbnail)}`);
            }
        };
        this.zoomablePhotoStore.resetZoom();
    }

    onNextClick() {
        if (this.galleryStore.photos.indexOf(this.galleryStore.selectedPhoto) === this.galleryStore.photos.length - 1) {
            return;
        }
        this.galleryStore.selectNextPhoto();
        this.galleryStore.setSelectedPhotoSrc(asJpegBlobUrl(this.galleryStore.selectedPhoto.thumbnail));
        this.loadImage();
        if (this.props.onSelectNeighbor) {
            this.props.onSelectNeighbor(this.galleryStore.selectedPhoto);
        }
    }

    onPrevClick() {
        if (this.galleryStore.photos.indexOf(this.galleryStore.selectedPhoto) === 0) {
            return;
        }
        this.galleryStore.selectPrevPhoto();
        this.galleryStore.setSelectedPhotoSrc(asJpegBlobUrl(this.galleryStore.selectedPhoto.thumbnail));
        this.loadImage();
        if (this.props.onSelectNeighbor) {
            this.props.onSelectNeighbor(this.galleryStore.selectedPhoto);
        }
    }

    getSizeString(width, height) {
        let res = `${width} × ${height} ~ ${width && height ? Math.round(width * height / 100000) / 10 : ''}MP`;
        return res;
    }

    onPerformScroll(e) {
        this.zoomablePhotoStore.onPerformScroll(e);
    }

    render() {
        let isFirstPhoto = this.galleryStore.photos.indexOf(this.galleryStore.selectedPhoto) === 0;
        let isLastPhoto = this.galleryStore.photos.indexOf(this.galleryStore.selectedPhoto) === this.galleryStore.photos.length - 1;

        return (
            <div className="image-full-contaiber">
                <div className="picture-block" onWheel={(e) => { this.onPerformScroll(e) }}>
                    <ContainerSizeSensor cn="bg-photo">
                        <Provider zoomablePhotoStore={this.zoomablePhotoStore}>
                            <ZoomablePhoto bgImage={this.galleryStore.selectedPhotoSrc}></ZoomablePhoto>
                        </Provider>
                    </ContainerSizeSensor>
                    {this.zoomablePhotoStore.zoom === 1 ? <div className="photo-arrow-container">
                        {!isFirstPhoto ?
                            <div className="photo-zone photo-left-zone" onClick={() => { this.onPrevClick() }}>
                                <div className="photo-arrow photo-arrow-prev">
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                            </div> : undefined}
                        {!isLastPhoto ?
                            <div className="photo-zone photo-right-zone" onClick={() => { this.onNextClick() }}>
                                <div className="photo-arrow photo-arrow-next">
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                            </div> : undefined}
                    </div> : undefined}
                </div>
                <div className="properties-block ps-3 pe-3">
                    <div className="properties-header-block pt-3 d-flex">
                        <span className="h3">Информация</span>
                        <span className="h3 ms-auto me-3 px-2" onClick={() => { this.props.hideModal() }} style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faXmark} /></span>
                    </div>
                    <div className="pt-3">
                        <div className="property-item-block h5 pt-2" title={this.galleryStore.selectedPhoto?.fileName}><FontAwesomeIcon icon={faImage} />{this.galleryStore.selectedPhoto?.fileName}</div>
                        <div className="property-item-block h5 pt-2"><FontAwesomeIcon icon={faCalendar} />{this.galleryStore.selectedPhoto?.creationTime?.toLocaleString("ru-RU")}</div>
                        <div className="property-item-block h5 pt-2"><FontAwesomeIcon icon={faPortrait} />{this.galleryStore.selectedPhoto?.ownerName}</div>
                        <div className="property-item-block h5 pt-2"><FontAwesomeIcon icon={faBorderAll} />{this.getSizeString(this.galleryStore.selectedPhoto?.width, this.galleryStore.selectedPhoto?.height)}</div>
                        <div className="property-item-block h5 pt-2"><FontAwesomeIcon icon={faFileImage} />{formatFileSize(this.galleryStore.selectedPhoto?.fileSize)}</div>
                        {this.galleryStore.selectedPhoto?.descriptions ?
                            <div className="property-item-block h5 pt-2" title={this.galleryStore.selectedPhoto?.descriptions}><FontAwesomeIcon icon={faTags} />{this.galleryStore.selectedPhoto?.descriptions}</div>
                            : undefined}
                        {this.galleryStore.selectedPhoto?.objects ?
                            <div className="property-item-block h5 pt-2" title={this.galleryStore.selectedPhoto?.objects}><FontAwesomeIcon icon={faMagnifyingGlass} />{this.galleryStore.selectedPhoto?.objects}</div>
                            : undefined}
                        {this.galleryStore.selectedPhoto?.latitude ?
                            <>
                                <div className="property-item-block h5 pt-2">
                                    <FontAwesomeIcon icon={faLocationDot} />{this.galleryStore.selectedPhoto?.latitude}, {this.galleryStore.selectedPhoto?.longitude}
                                </div>
                                <YMaps>
                                    <Map
                                        width="100%"
                                        defaultState={{ center: [this.galleryStore.selectedPhoto?.latitude, this.galleryStore.selectedPhoto?.longitude], zoom: 13 }}
                                        state={{ center: [this.galleryStore.selectedPhoto?.latitude, this.galleryStore.selectedPhoto?.longitude], zoom: 13 }} >
                                        <Placemark
                                            geometry={[this.galleryStore.selectedPhoto?.latitude, this.galleryStore.selectedPhoto?.longitude]}
                                            options={{
                                                preset: 'islands#geolocationIcon',
                                                iconColor: 'red',
                                            }}
                                        />
                                    </Map>
                                </YMaps>
                            </>
                            : undefined}
                    </div>
                    <div className="action-buttons-block py-3 h3 mt-auto mb-0">
                        {this.props.onMarkFavourite
                            ? <div className="action-button action-button-gold"
                                onClick={() => { this.props.onMarkFavourite() }}
                                title="Избранное">
                                {this.galleryStore.selectedPhoto.isFavourite
                                    ? <FontAwesomeIcon icon={faStar} />
                                    : <FontAwesomeIcon icon={faRegStar} />}
                            </div>
                            : undefined}
                        {this.props.onDelete
                            ? <div className="action-button action-button-red"
                                onClick={() => { this.props.onDelete(this.galleryStore.selectedPhoto) }}
                                title="Удалить">
                                <FontAwesomeIcon icon={faTrashCan} /></div>
                            : undefined}
                        {this.props.canDownload
                            ? <a className="action-button action-button-blue"
                                download={this.galleryStore.selectedPhoto?.fileName}
                                href={'api/photos/GetPhotoFile?photoId=' + this.galleryStore.selectedPhoto?.id}
                                title="Скачать">
                                <FontAwesomeIcon icon={faCircleDown} /></a>
                            : undefined}
                    </div>
                </div>
            </div>
        );
    }
}

export default inject('galleryStore')(observer(ImageFull))