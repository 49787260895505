import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { getPluralForm } from '../helpers/getPluralForm';
import "./BottomOptions.css"
import { observer, inject } from 'mobx-react';
import { asJpegBlob } from '../helpers/encodeBase64Url';

class Albums extends Component {
    static displayName = Albums.name;

    constructor(props) {
        super(props);

        this.albumsStore = props.albumsStore;
    }

    async componentDidMount() {
        await this.albumsStore.loadAlbums();
    }

    render() {
        return (
            <>
                <div className="d-inline-flex flex-wrap w-100" >
                    {this.albumsStore.albums.map(album =>
                        <div key={album.id} className="card my-1 mx-1 album-card">
                            <img onClick={() => { this.props.onAlbumClick(album) }} className="card-img-top" src={album.coverPhoto == null ? '/no-image.png' : asJpegBlob(album.coverPhoto.thumbnail)} alt="Обложка альбома" />
                            <div onClick={() => { this.props.onAlbumClick(album) }} className="card-body">
                                <h5 className="card-title">{album.name}</h5>
                                <p className="card-text">{album.description}</p>
                            </div>
                            <div className="card-footer d-inline-flex">
                                <small className="text-muted me-auto">{album.itemsCount} {getPluralForm(album.itemsCount, "объект", "объекта", "объектов")}</small>
                                {this.props.showOptions ?
                                    <div className="dropdown">
                                        <div type="button" id={`dropdownMenuButton1${album.id}`} data-bs-toggle="dropdown" aria-expanded="false">
                                            <FontAwesomeIcon icon={faEllipsis} />
                                        </div>
                                        <ul className="dropdown-menu" aria-labelledby={`dropdownMenuButton1${album.id}`}>
                                            {this.props.onAlbumRename
                                                ? <li><span className="dropdown-item" onClick={() => { this.props.onAlbumRename(album) }}>Изменить название и описание</span></li>
                                                : undefined}
                                            {this.props.onCoverChange
                                                ? <li><span className="dropdown-item" onClick={() => { this.props.onCoverChange(album) }}>Изменить обложку</span></li>
                                                : undefined}
                                            {this.props.onAlbumDelete
                                                ? <li><span className="dropdown-item" onClick={() => { this.props.onAlbumDelete(album) }}>Удалить</span></li>
                                                : undefined}
                                        </ul>
                                    </div> : undefined}
                            </div>
                        </div>
                    )}
                </div>
                {this.albumsStore.loading
                    ? <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : <></>}
            </>
        );
    }
}

export default inject('albumsStore')(observer(Albums))