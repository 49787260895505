const api = {
    photos: {
        uploadPhotos: async (file, albumId) => {
            let uploadEndpoint = "api/photos/uploadphotos"
            if (albumId) {
                uploadEndpoint += `?albumId=${albumId}`
            }
            const formData = new FormData();
            formData.append('files', file);
            let response = await fetch(uploadEndpoint, {
                method: 'post',
                body: formData
            });
            return response;
        },
        deletePhotos: async (photoIds) => {
            let response = await fetch("api/photos/deletephotos", {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(photoIds),
            });
            return response;
        },
        getPhotos: async (skip, take, archive, favourite) => {
            let loadPhotosEndpoint = `api/photos/getphotos?skip=${skip}&take=${take}`;
            if (archive) {
                loadPhotosEndpoint += `&archived=${true}`;
            } else if (favourite) {
                loadPhotosEndpoint += `&favourite=${true}`;
            }
            let response = await fetch(loadPhotosEndpoint);
            return response;
        },
        archivePhotos: async (photoIds: number[], archive: bool) => {
            let response = await fetch("api/photos/archivephotos", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    photoIds: photoIds,
                    archive: archive,
                }),
            });
            return response;
        },
        markPhotoFavourite: async (photoIds: number[], markFavourite: bool) => {
            let response = await fetch("api/photos/MarkPhotoFavourite", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    photoIds: photoIds,
                    markFavourite: markFavourite,
                }),
            });
            return response;
        },
        getPhotosOnMap: async (x1, x2, y1, y2) => {
            let response = await fetch(`api/photos/getPhotosOnMap?x1=${x1}&x2=${x2}&y1=${y1}&y2=${y2}`);
            return response;
        },
    },
    users: {
        changeUserPassword: async (currentPassword, newPassword) => {
            let response = await fetch(`api/user/changeuserpassword`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                }),
            });
            return response;
        },
        login: async (formData) => {
            let response = await fetch(`api/user/login`, {
                method: 'post',
                body: formData
            });
            return response;
        },
        logout: async () => {
            let response = await fetch(`api/user/logout`, { method: "post" });
            return response;
        },
        isSignedIn: async () => {
            let response = await fetch(`api/user/isSignedIn`);
            return response;
        },
    },
    albums: {
        getAlbum: async (skip, take, albumId) => {
            let loadPhotosEndpoint = `api/albums/getalbum?skip=${skip}&take=${take}&albumId=${albumId}`;
            let response = await fetch(loadPhotosEndpoint);
            return response;
        },
        getAlbums: async () => {
            let response = await fetch(`api/albums/getalbums`);
            return response;
        },
        getAlbumShort: async (albumId) => {
            let response = await fetch(`api/albums/getalbumshort?albumId=${albumId}`);
            return response;
        },
        createAlbum: async (name, description, photoIds, coverPhotoId) => {
            let response = await fetch("api/albums/createalbum", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    coverPhotoId: coverPhotoId,
                    albumPhotoIds: photoIds,
                }),
            });
            return response;
        },
        addToAlbum: async (albumId, photoIds) => {
            let response = await fetch("api/albums/addtoalbum", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    albumId: albumId,
                    albumPhotoIds: photoIds,
                }),
            });
            return response;
        },
        removeFromAlbum: async (albumId, photoIds) => {
            let response = await fetch("api/albums/removefromalbum", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    albumId: albumId,
                    albumPhotoIds: photoIds,
                }),
            });
            return response;
        },
        deleteAlbum: async (albumId) => {
            let response = await fetch(`api/albums/deletealbum?albumId=${albumId}`, {
                method: 'delete',
            });
            return response;
        },
        updateAlbum: async (albumId, name, description) => {
            let response = await fetch(`api/albums/updatealbum`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    albumId: albumId,
                    name: name,
                    description: description,
                }),
            });
            return response;
        },
        changeAlbumCover: async (albumId, coverId) => {
            let response = await fetch(`api/albums/changealbumcover`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    albumId: albumId,
                    coverId: coverId,
                }),
            });
            return response;
        }
    }
}
export default api;