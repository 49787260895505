import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import api from '../api';
import helpers from '../helpers';
import { inject, observer } from "mobx-react"

class LoginPage extends Component {
    static displayName = LoginPage.name;

    constructor(props) {
        super(props);
        this.userStore = props.userStore;
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.state = { returnUrl: new URLSearchParams(props.location.search).get('ReturnUrl') };
    }

    async onSubmitClick(e) {
        e.preventDefault();
        let response = await helpers.alertError(await api.users.login(new FormData(e.target)));
        if (response.ok) {
            this.userStore.changeUser(await response.text());
            if (this.state.returnUrl != null) {
                window.location.assign(this.state.returnUrl);
            }
            else {
                var returnUrl = new URLSearchParams(this.props.location.search).get('returnUrl') ?? '/gallery';
                this.props.history.push(returnUrl);
            }
        }
    }

    render() {
        return (
            <div>
                <Form onSubmit={this.onSubmitClick}>
                    <FormGroup className="mb-3">
                        <Label for="userName" className="form-label">Логин</Label>
                        <Input id="userName" name="userName" type="text" className="form-control" />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="password" className="form-label">Пароль</Label>
                        <Input id="password" name="password" type="password" className="form-control" />
                    </FormGroup>
                    <FormGroup className="mb-3 form-check">
                        <Input id="keepLoggedIn" name="keepLoggedIn" type="checkbox" className="form-check-input" />
                        <Label for="keepLoggedIn" className="form-check-label">Запомнить меня</Label>
                    </FormGroup>
                    <Button className="btn btn-primary" type="submit">Войти</Button>
                </Form>
            </div>
        );
    }
}

export default inject('userStore')(observer(LoginPage));