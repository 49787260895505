import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import './NavMenu.css';
import api from '../api';
import helpers from '../helpers';
import { inject, observer } from "mobx-react"
import Consts from '../helpers/consts'

class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.getLoginUrl = (currentUrl) => { return `${Consts.loginUrl}?returnUrl=${encodeURIComponent(currentUrl)}` }
        this.userStore = this.props.userStore;

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.state = {
            collapsed: true,
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    async handleLogout() {
        if (window.confirm("Подтвердите выход")) {
            let response = await helpers.alertError(await api.users.logout());
            if (response.ok) {
                this.userStore.changeUser(null);
                this.props.history.push(`/`);
            }
        }
    }

    render() {
        let isActive = (...routes) => {
            return routes.some(m => this.props.location.pathname.startsWith(m)) ? true : undefined;
        };

        return (
            <header className="nav-header position-sticky top-0 bg-white">
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">Фото галерея</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                {/*<NavItem>*/}
                                {/*    <NavLink tag={Link} className="text-dark" to="/">Главная</NavLink>*/}
                                {/*</NavItem>*/}
                                {this.userStore.userName
                                    ? <>
                                        <NavItem>
                                            <NavLink tag={Link} to="/map" active={isActive("/map")}>Карта</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/albums" active={isActive("/albums", "/album", "/addtoalbum", "/createalbum")}>Альбомы</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/gallery" active={isActive("/gallery")}>Галерея</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/favourites" active={isActive("/favourites")}>Избранное</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/archive" active={isActive("/archive")}>Архив</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} to="/user" active={isActive("/user")}><FontAwesomeIcon icon={faCircleUser} /></NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink onClick={this.handleLogout} style={{ cursor: "pointer" }} >Выйти</NavLink>
                                        </NavItem>
                                    </>
                                    : <NavItem>
                                        <a href={this.getLoginUrl(document.URL)} className="nav-link-btn">Войти</a>
                                    </NavItem>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

export default inject('userStore')(withRouter(observer(NavMenu)));