export function formatFileSize(size) {
    if (size < 1024) {
        return `${size}B`;
    }
    if (size < 1024 * 1024) {
        let res = Math.round(size / 102.4) / 10;
        return `${res}KB`;
    }
    if (size < 1024 * 1024 * 1024) {
        let res = Math.round(size / (102.4 * 1024)) / 10;
        return `${res}MB`;
    }
}