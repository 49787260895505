import { action, makeObservable, observable } from 'mobx';

export default class ZoomablePhotoStore {
    constructor() {
        makeObservable(this, {
            zoom: observable,
            posX: observable,
            posY: observable,
            blockWidth: observable,
            blockHeight: observable,
            imageWidth: observable,
            imageHeight: observable,
            baseHeight: observable,

            onPerformScroll: action,
            onSetBlockAndImage: action,
            onPerformMove: action,
            resetZoom: action,
        });
    }

    zoom = 1;
    posX = 0;
    posY = 0;

    blockWidth = 0;
    blockHeight = 0;
    imageWidth = 0;
    imageHeight = 0;
    baseHeight = 0;

    onPerformScroll(e) {
        //До зума
        let realImageHeight = this.baseHeight * this.zoom;
        let realImageWidth = realImageHeight / this.imageHeight * this.imageWidth;
        let relativeX = (this.blockWidth * 0.5 - this.posX) / realImageWidth;
        let relativeY = (this.blockHeight * 0.5 - this.posY) / realImageHeight;
        //---

        if (e.deltaY > 0) {
            this.zoom /= 1.1;
        }
        else {
            this.zoom *= 1.1;
        }
        if (this.zoom < 1) this.zoom = 1;
        if (this.zoom > 13.1) this.zoom = 13.1;

        //После зума
        realImageHeight = this.baseHeight * this.zoom;
        realImageWidth = realImageHeight / this.imageHeight * this.imageWidth;

        let moveX = realImageWidth * relativeX - this.blockWidth * 0.5 + this.posX;
        let moveY = realImageHeight * relativeY - this.blockHeight * 0.5 + this.posY;
        //---

        this.onPerformMove(moveX, moveY);
    }

    onSetBlockAndImage(blockWidth, blockHeight, imageWidth, imageHeight, baseHeight) {
        this.blockWidth = blockWidth;
        this.blockHeight = blockHeight;
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
        this.baseHeight = baseHeight;
        this.onPerformMove(0, 0);
    }

    onPerformMove(moveX, moveY) {
        let realImageHeight = this.baseHeight * this.zoom;
        let realImageWidth = realImageHeight / this.imageHeight * this.imageWidth;

        let minPosX = this.blockWidth < realImageWidth ? 0 : ((this.blockWidth - realImageWidth) / 2);
        let maxPosX = this.blockWidth < realImageWidth ? (this.blockWidth - realImageWidth) : ((this.blockWidth - realImageWidth) / 2);
        let minPosY = this.blockHeight < realImageHeight ? 0 : ((this.blockHeight - realImageHeight) / 2);
        let maxPosY = this.blockHeight < realImageHeight ? (this.blockHeight - realImageHeight) : ((this.blockHeight - realImageHeight) / 2);

        this.posX -= moveX;
        this.posY -= moveY;
        if (this.posX > minPosX) this.posX = minPosX;
        if (this.posX < maxPosX) this.posX = maxPosX;
        if (this.posY > minPosY) this.posY = minPosY;
        if (this.posY < maxPosY) this.posY = maxPosY;
    }

    resetZoom() {
        this.zoom = 1;
    }
}
