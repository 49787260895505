import { action, makeObservable, observable, runInAction } from 'mobx';
import helpers from '../helpers';
import api from '../api';
import { listElement } from '../helpers/listElement';

export default class GalleryStore {
    constructor(albumId, archive, favourite) {
        this.albumId = albumId;
        this.archive = archive;
        this.favourite = favourite;

        makeObservable(this, {
            photos: observable,
            loading: observable,
            totalCount: observable,
            skip: observable,
            selectedPhotos: observable,
            selectedPhoto: observable,
            selectedPhotoSrc: observable,
            albumName: observable,

            loadPhotos: action,
            refresh: action,
            selectPhoto: action,
            clearMultiSelection: action,
            toggleMultiSelection: action,
            markSelectedPhotosFavourite: action,
            markSelectedPhotoFavourite: action,
            selectNextPhoto: action,
            selectPrevPhoto: action,
            setSelectedPhotoSrc: action,
        });

        this.events = new EventTarget();
    }
    //Константы
    initialCount = 30;

    //Переменные
    photos = [];
    loading = true;
    totalCount = 0;
    skip = 0;
    selectedPhotos = [];
    selectedPhoto = null;
    selectedPhotoSrc = "";
    albumName = "Галерея";

    refresh = () => {
        this.photos = [];
        this.loading = true;
        this.totalCount = 0;
        this.skip = 0;
        this.selectedPhotos = [];
        this.selectedPhoto = null;
        this.selectedPhotoSrc = "";
        this.albumName = "Галерея";
    }

    setAlbumId = (albumId) => {
        this.albumId = albumId;
    }

    setSelectedPhotoSrc = (src) => {
        this.selectedPhotoSrc = src;
    }

    selectNextPhoto = () => {
        if (this.selectedPhoto) {
            let index = this.photos.indexOf(this.selectedPhoto);
            if (index < this.photos.length - 1) {
                this.selectPhoto(this.photos[index + 1]);
            }
        }
    }

    selectPrevPhoto = () => {
        if (this.selectedPhoto) {
            let index = this.photos.indexOf(this.selectedPhoto);
            if (index > 0) {
                this.selectPhoto(this.photos[index - 1]);
            }
        }
    }

    selectPhoto = (photo) => {
        this.selectedPhoto = photo;
    }

    markSelectedPhotoFavourite = async () => {
        let response = await helpers.alertError(await api.photos.markPhotoFavourite([this.selectedPhoto.id], !this.selectedPhoto.isFavourite));
        if (response.ok) {
            runInAction(() => {
                this.selectedPhoto.isFavourite = !this.selectedPhoto.isFavourite;
            });
        }
    }

    markSelectedPhotosFavourite = async (favourite) => {
        let response = await helpers.alertError(await api.photos.markPhotoFavourite(this.selectedPhotos, favourite));
        if (response.ok) {
            let selectedPhotos = this.photos.filter(m => this.selectedPhotos.some(ms => ms === m.id));
            selectedPhotos.forEach(m => m.isFavourite = favourite);
            return true;
        }
        return false;
    }

    loadPhotos = async (skip, take) => {
        this.loading = true;

        if (this.albumId) {
            var response = await helpers.alertError(await api.albums.getAlbum(skip, take, this.albumId));
        } else {
            response = await helpers.alertError(await api.photos.getPhotos(skip, take, this.archive, this.favourite));
        }
        if (response.ok) {
            const data = await response.json();

            data.list.forEach(m => { m.creationTime = new Date(m.creationTime) });

            runInAction(() => {
                this.photos = this.photos.concat(data.list);
                this.loading = false;
                this.totalCount = data.totalCount;
                this.albumName = data.albumName;
                this.skip = this.skip + take
            });
            this.events.dispatchEvent(new CustomEvent('photosLoaded'));
        }
    }

    toggleMultiSelection = (photoId) => {
        this.selectedPhotos = listElement(this.selectedPhotos, photoId);
    }

    clearMultiSelection = () => {
        this.selectedPhotos = [];
    }
}