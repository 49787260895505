export function getPluralForm(count, single, few, many) {
    if (count % 100 > 10 && count % 100 < 20) {
        return many;
    }
    switch (count % 10) {
        case 1: return single;
        case 2: return few;
        case 3: return few;
        case 4: return few;
        default: return many;
    }
}