import api from '../api';
import helpers from '../helpers';
import { action, makeObservable, observable, runInAction } from 'mobx';

export default class MapStore {
    constructor() {
        makeObservable(this, {
            photos: observable,
            avgPosX: observable,
            avgPosY: observable,
            loading: observable,
            selectedPhotoId: observable,

            loadPhotos: action,
            selectPhoto: action,
        });
    }

    avgPosX = 0;
    avgPosY = 0;
    photos = [];
    selectedPhotoId = null;
    loading = true;

    loadPhotos = async () => {
        this.loading = true;
        let response = await helpers.alertError(await api.photos.getPhotosOnMap(0, 180, 0, 180));
        if (response.ok) {
            const data = await response.json();
            let avgPosX = data.reduce((a, b) => a + b.latitude, 0) / data.length;
            let avgPosY = data.reduce((a, b) => a + b.longitude, 0) / data.length;
            runInAction(() => {
                this.photos = data;
                this.avgPosX = avgPosX;
                this.avgPosY = avgPosY;
                this.loading = false;
            });
        }
    }

    selectPhoto(id) {
        this.selectedPhotoId = id;
    }
}