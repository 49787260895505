const helpers = {
    alertError: async function (response: Response, errorMessage?: string): Response {
        if (response.ok) {
            return response;
        }
        let errorToast = { head: "Ошибка", body: "" }
        if ((response.status >= 400) && (response.status < 500)) {
            if (errorMessage) {
                errorToast.body = errorMessage;
            }
            else {
                let error = await response.text();
                errorToast.body = error;
            }
        }
        document.notify(errorToast);
        return response;
    }
}

export default helpers